/* StyledTable.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.table-container {
  width: 100%;
  overflow-x: auto;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

.styled-table th,
.styled-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.styled-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.styled-table tbody tr:hover {
  background-color: #ddd;
}

.title-button {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.primary-button {
  background-color: #7b3fff;
  border: 0px;
  color: white;
  border-radius: 99px;
  width: 150px;
}

.primary-link {
  background-color: #7b3fff;
  border: 0px;
  color: white;
  border-radius: 99px;
  width: 150px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  justify-items: center;
}

.pages-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media (min-width: 768px) {
  .pages-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.primary-link-pages {
  text-decoration: none;
  white-space: nowrap;
  width: 200px;
  padding-top: 12px;
  padding-left: 20px;
}
.page-card {
  color: #353535;
  text-decoration: none;
}
.page-banner {
  background-size: cover;
  width: 100%;
  height: 350px;
  border-radius: 12px 12px 0px 0px;
  background-clip: border-box;
}
.service-image {
  background-size: cover;
  width: 100%;
  height: 350px;
  border-radius: 12px;
}
.page-number {
  margin-top: 6px;
  font-weight: 500;
}
.page-number-label {
  font-size: 12px;
}

.default-input {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border:solid 2px #6b7280;
}

.current_link{
  background-color: transparent !important;
  width: 100%;
}
