.active {
  background-color: #2d1c52;
  width: 100%;
}

.contact-input{
  padding:.6rem;
  border: 2px solid #929292;
  border-radius: 10rem;
}

#navlink {
  padding: 10px 5px;
  margin: 5px 10px;
  text-decoration: none;
  border-radius: 15px;
  width: 100%;
  text-transform: none;
}

#navlink:hover {
  background-color: #6e44ca;
}

#navlink:focus {
  background-color: #2d1c52;
}

.sevices-grid {
  margin-top: 30px;
  display: grid;
  gap: 10px;
  row-gap: 10px;
  place-content: center;
  place-items: center;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: 768px) {
  .sevices-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.bdisplay-button {
  width: 100%;
  background-color: white;
  border: solid 1px gray;
  border-radius: 99px;
  padding: 7px;
  text-align: center;
  text-decoration: none;
  color: #353535;
}

.extraSmall{
  display: block;
}

@media  screen and (width < 350px) {
  .extraSmall{
    display: none;
  }
}

@media  screen and (768px < width < 947px) {
  .extraSmall{
    display: none;
  }
}